import cx from 'classnames';
import { Box, IBoxProps } from '@/src/packages/components/ui/Box';

export const NavigationItem = (props: IBoxProps) => {
  const { className, children, ...rest } = props;

  return (
    <Box className={cx('flex items-center h-full', className)} {...rest}>
      {children}
    </Box>
  );
};
