import { createContext } from '@bitmap/utils/utils/createContext';
import { NavigationSubmenuIds } from '../NavigationSubmenu';

export type INavigationContext = {
  setHovered(state: boolean): void;

  isHovered: boolean;
  isInversed: boolean;
  isFixed: boolean;
  isPinned: boolean;

  handleOpenMobileMenu(): void;
  handleCloseMobileMenu(): void;
  isMobileMenuOpen: boolean;

  handleOpenSubMenu(id: NavigationSubmenuIds): void;
  handleCloseSubMenu(): void;
  isSubmenuOpen: boolean;
  activeSubmenuId: NavigationSubmenuIds | null;
};

export const [NavigationContext, useNavigationContext] =
  createContext<INavigationContext>();
