import Link from 'next/link';
import { Stack } from '../../../Stack';
import { NavigationMobileDrawerSecondaryLink } from './NavigationMobileDrawerSecondaryLink';
import { getRoute } from '@/src/packages/utils/routes';
import { paths } from '@/src/packages/routes';
import { Variants, motion } from 'framer-motion';
import { useState } from 'react';
import { pathParams } from '@/src/packages/routes/pathParams.enum';
import { cn } from '@/src/packages/utils/cn';

const links: { title: string; href: string }[] = [
  {
    title: 'Shop',
    href: getRoute({
      route: paths.RANGES_SINGLE,
      params: { [pathParams.RANGE_SINGLE]: 'dog' },
    }),
  },
  {
    title: 'Journal',
    href: getRoute({
      route: paths.BLOG_INDEX,
    }),
  },
  {
    title: 'Materials',
    href: getRoute({
      route: paths.MATERIALS_INDEX,
    }),
  },
  {
    title: 'Warranty',
    href: getRoute({
      route: paths.LIFETIME_WARRANTY_INDEX,
    }),
  },

  {
    title: 'About',
    href: getRoute({
      route: paths.ABOUT_INDEX,
    }),
  },
  {
    title: 'Contact',
    href: getRoute({
      route: paths.CONTACT_INDEX,
    }),
  },
  {
    title: 'Delivery & returns',
    href: getRoute({
      route: paths.DELIVERY_RETURNS_INDEX,
    }),
  },
  {
    title: 'Product care',
    href: getRoute({
      route: paths.PRODUCT_CARE_INDEX,
    }),
  },
  {
    title: 'FAQs',
    href: getRoute({
      route: paths.FAQS_INDEX,
    }),
  },
];

export const NavigationMobileDrawerSecondaryLinks = () => {
  const parentVariant: Variants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { staggerChildren: 0.15, delayChildren: 0.15 },
    },
  };

  const childrenVariant: Variants = {
    initial: { opacity: 0, x: -20 },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.25, // specify the duration for the children animations here
      },
    },
  };

  const [hovered, setHovered] = useState<number | null>(null);

  return (
    <Stack spaceY={4}>
      <motion.div initial="initial" animate="animate" variants={parentVariant}>
        {links.map((link, index) => {
          return (
            <motion.div
              key={index}
              variants={childrenVariant}
              onMouseEnter={() => setHovered(index)}
            >
              <Link href={link.href}>
                <NavigationMobileDrawerSecondaryLink
                  onMouseEnter={() => setHovered(index)}
                  onMouseLeave={() => setHovered(null)}
                  className={cn({
                    'opacity-50': hovered !== null && hovered !== index,
                  })}
                >
                  {link.title}
                </NavigationMobileDrawerSecondaryLink>
              </Link>
            </motion.div>
          );
        })}
      </motion.div>

      {/* <Link href={getRoute({ route: paths.ABOUT_INDEX })}>
        <NavigationMobileDrawerSecondaryLink>
          About
        </NavigationMobileDrawerSecondaryLink>
      </Link>
      <Link href={getRoute({ route: paths.FAQS_INDEX })}>
        <NavigationMobileDrawerSecondaryLink>
          FAQs
        </NavigationMobileDrawerSecondaryLink>
      </Link>
      <Link href={getRoute({ route: paths.BLOG_INDEX })}>
        <NavigationMobileDrawerSecondaryLink>
          Journal
        </NavigationMobileDrawerSecondaryLink>
      </Link>
      <Link href={getRoute({ route: paths.MATERIALS_INDEX })}>
        <NavigationMobileDrawerSecondaryLink>
          Materials
        </NavigationMobileDrawerSecondaryLink>
      </Link>
      <Link href={getRoute({ route: paths.CONTACT_INDEX })}>
        <NavigationMobileDrawerSecondaryLink>
          Contact
        </NavigationMobileDrawerSecondaryLink>
      </Link> */}
    </Stack>
  );
};
