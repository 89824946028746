import { ReactNode, RefObject } from 'react';
import { Box } from '@/src/packages/components/ui/Box';
import { Container } from '@/src/packages/components/ui/Container';

export const NavigationContainer = ({
  children,
  navigationRef,
}: {
  children: ReactNode;
  navigationRef: RefObject<HTMLDivElement>;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="full"
      position="relative"
      className="h-navigation-height shrink-0 border border-transparent"
      transitionDuration="default"
      ref={navigationRef}
    >
      <Container className="h-full">{children}</Container>
    </Box>
  );
};
