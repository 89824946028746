import { forwardRef, ReactNode, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { Box, IBoxProps } from '../../Box';
import { useNavigationContext } from '../NavigationContext';
import { NavigationSubmenuIds } from '../NavigationSubmenu';
import clsx from 'clsx';
import { Link } from '@/src/packages/components/ui/Link';

type INavigationLink = {
  children: ReactNode;
  submenuId?: NavigationSubmenuIds;
} & IBoxProps;

export const NavigationLink = forwardRef<HTMLDivElement, INavigationLink>(
  (props, forwardedRef) => {
    const { children, onMouseEnter, onMouseLeave, submenuId, ...rest } = props;

    const ref = useRef<HTMLDivElement>(null);

    return (
      <Link className="text-sm" {...rest}>
        {children}
      </Link>
    );
  },
);

NavigationLink.displayName = 'NavigationLink';
