import { RefObject } from 'react';
import { useNavigationContext } from '../NavigationContext';
import { useLockBodyScroll } from 'react-use';
import { NavigationMobileDrawerSecondaryLinks } from './NavigationMobileDrawerSecondaryLinks';
import { NavigationLocaleSelectorTrigger } from '@/src/packages/components/ui/Navigation/NavigationLocaleSelector';
import { useLocaleDrawer } from '@/src/packages/hooks/use-basket-drawer';
import { Box } from '@/src/packages/components/ui/Box';
import { cn } from '@/src/packages/utils/cn';
import { Sheet, SheetContent } from '@/src/packages/components/ui/Sheet';
import { Link } from '@/src/packages/components/ui/Link';

export const NavigationMobileDrawer = ({
  mobileDrawerRef,
}: {
  mobileDrawerRef: RefObject<HTMLDivElement>;
}) => {
  const { isMobileMenuOpen, handleCloseMobileMenu } = useNavigationContext();

  useLockBodyScroll(isMobileMenuOpen);

  const { handleOpen: handleOpenLocaleSelector } = useLocaleDrawer();

  return (
    <Sheet
      open={isMobileMenuOpen}
      onOpenChange={handleCloseMobileMenu}
      modal={false}
    >
      <SheetContent
        side="left"
        className={cn(
          'flex w-full max-w-4xl flex-1 flex-col overflow-scroll p-12',
        )}
      >
        <Box className="ml-auto flex w-full max-w-lg flex-1 flex-col">
          <Box className="flex">
            <Box
              onClick={handleCloseMobileMenu}
              className="ml-auto"
              as="button"
            >
              Close
            </Box>
          </Box>
          <NavigationMobileDrawerSecondaryLinks />

          <Box className="mt-auto">
            <Link onClick={handleOpenLocaleSelector} className=" inline-block">
              <NavigationLocaleSelectorTrigger />
            </Link>
          </Box>
        </Box>
      </SheetContent>
    </Sheet>
  );
};
