'use client';

import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import clsx from 'clsx';
import { VariantProps, tv } from 'tailwind-variants';
import { Box } from '@/src/packages/components/ui/Box';

const labelVariants = tv({
  base: 'leading-none font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 block',
});

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> & { required?: boolean }
>(({ className, children, required, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={clsx(labelVariants(), className)}
    {...(required && { 'aria-required': true })}
    {...props}
  >
    {children}
    {required && <Box as="span">*</Box>}
  </LabelPrimitive.Root>
));

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
