import { paths } from '@/src/packages/routes';
import { getRoute } from '@/src/packages/utils/routes';
import { GlyphLetter, Wordmark } from '@/src/packages/components/ui/Logo';
import { useNavigationContext } from '@/src/packages/components/ui/Navigation/NavigationContext';
import Link from 'next/link';
import { cn } from '@/src/packages/utils/cn';

export const NavigationLogo = () => {
  const { isPinned } = useNavigationContext();

  return (
    <Link
      href={getRoute({ route: paths.HOME_INDEX, params: {} })}
      className={cn(
        'absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2 transform',
        {
          'w-8 md:w-8': isPinned,
          'w-[200px]': !isPinned,
        },
      )}
      aria-label="Go back to homepage"
    >
      {isPinned ? <GlyphLetter /> : <Wordmark />}
    </Link>
  );
};
