'use client';
import { NavigationLogo } from './NavigationLogo';
import { NavigationContainerInner } from './NavigationContainerInner';
import { NavigationAnnouncement } from './NavigationAnnouncement';
import { useEffect, useRef, useState } from 'react';
import { NavigationDesktopPrimaryLinks } from './NavigationDesktop';
import { NavigationContainer } from './NavigationContainer';
import { NavigationMobileDrawer } from './NavigationMobileDrawer';
import { INavigationContext, NavigationContext } from './NavigationContext';
import { NavigationSecondaryLinks } from './NavigationSecondaryLinks';
import { useNavigation } from './useNavigation';
import { Box } from '@/src/packages/components/ui/Box';
import { NavigationHamburger } from '@/src/packages/components/ui/Navigation/NavigationHamburger';
import { create } from 'zustand';
import { cn } from '@/src/packages/utils/cn';
import { usePathname } from 'next/navigation';

type INavigationProps = {
  isNavigationInversed: boolean;
};

export const useNavigationInverse = create<{
  setInversed(state: boolean): void;
  isInversed: boolean;
}>((set) => ({
  isInversed: false,
  setInversed: (state) => set(() => ({ isInversed: state })),
}));

export const useInversedNav = () => {
  const { setInversed } = useNavigationInverse();

  useEffect(() => {
    setInversed(true);
  }, []);
};

export const Navigation = (props: INavigationProps) => {
  const { isNavigationInversed } = props;

  const {
    activeSubmenuId,
    isPinned,
    isMobileMenuOpen,
    handleCloseMobileMenu,
    handleOpenMobileMenu,
    isSubmenuOpen,
    handleCloseSubMenu,
    handleOpenSubMenu,
  } = useNavigation();

  const navigationContainerRef = useRef<HTMLDivElement>(null);

  const navigationDrawerToggle = useRef<HTMLDivElement>(null);

  const navigationRef = useRef<HTMLDivElement>(null);

  const mobileDrawerRef = useRef<HTMLDivElement>(null);

  const [isHovered, setHovered] = useState<boolean>(false);

  const ctx: INavigationContext = {
    isMobileMenuOpen,
    handleCloseMobileMenu,
    handleOpenMobileMenu,
    isHovered,
    isPinned,
    isFixed: true,
    isInversed: isNavigationInversed,
    setHovered,
    isSubmenuOpen,
    activeSubmenuId,
    handleCloseSubMenu,
    handleOpenSubMenu,
  };

  const { isInversed, setInversed } = useNavigationInverse();

  const pathname = usePathname();

  useEffect(() => {
    console.log('turning off');
    setInversed(false);
  }, [pathname]);

  return (
    <NavigationContext value={ctx}>
      <Box
        as="nav"
        ref={navigationContainerRef}
        className={cn('top-0 flex w-full flex-col transition-all', {
          'text-white': isInversed,
        })}
        transitionDuration="default"
        zIndex="navigation"
      >
        <NavigationAnnouncement />
        <NavigationContainer navigationRef={navigationRef}>
          <NavigationContainerInner>
            <NavigationHamburger ref={navigationDrawerToggle} />
            <NavigationLogo />
            <NavigationDesktopPrimaryLinks />
            <NavigationSecondaryLinks />
          </NavigationContainerInner>
        </NavigationContainer>
        <NavigationMobileDrawer mobileDrawerRef={mobileDrawerRef} />
      </Box>
    </NavigationContext>
  );
};
