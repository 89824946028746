import { ReactNode } from 'react';
import { Box, IBoxProps } from '@/src/packages/components/ui/Box';
import { cn } from '@/src/packages/utils/cn';
import { Stack } from '@/src/packages/components/ui/Stack';
import Link from 'next/link';

type IBreadcrumbItemProps = {
  isCurrent: boolean;
  children: ReactNode;
};

export const BreadCrumbTrailItem = (props: IBreadcrumbItemProps) => {
  const { isCurrent, children } = props;

  return (
    <Box
      className={cn('whitespace-nowrap leading-none', {
        'cursor-pointer underline-offset-4 hover:underline': isCurrent,
        'opacity-50': !isCurrent,
      })}
    >
      {children}
    </Box>
  );
};

export type IBreadcrumbItem = {
  label: string;
  path?: string;
};

type IBreadcrumbProps = {
  items: IBreadcrumbItem[];
} & Pick<IBoxProps, 'className'>;

export const Breadcrumb = (props: IBreadcrumbProps) => {
  const { items, className, ...rest } = props;

  return (
    <Stack
      direction="horizontal"
      className={cn(
        'inline-flex items-center space-x-2 overflow-scroll',
        className,
      )}
      divider={
        <Box
          className="relative text-sm opacity-30"
          style={{ marginTop: '2px' }}
        >
          /
        </Box>
      }
      {...rest}
    >
      {items.map((item, i) => {
        if (item.path) {
          return (
            <Link
              href={item.path}
              className="flex items-center leading-none"
              key={i}
            >
              <BreadCrumbTrailItem isCurrent={true}>
                {item.label}
              </BreadCrumbTrailItem>
            </Link>
          );
        } else {
          return (
            <BreadCrumbTrailItem isCurrent={false} key={i}>
              {item.label}
            </BreadCrumbTrailItem>
          );
        }
      })}
    </Stack>
  );
};
