import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { Box } from '@/src/packages/components/ui/Box';
import {
  useShippingReturnsDrawerStore,
  useWarrantyDrawerStore,
} from '@/src/packages/hooks/use-basket-drawer';
import Marquee from 'react-fast-marquee';

export const NavigationAnnouncement = () => {
  const { handleOpen } = useShippingReturnsDrawerStore();

  const { handleOpen: handleOpenWarranty } = useWarrantyDrawerStore();

  const { shippingRates, currencyCode, shippingCountry } = useStoreContext();

  // Find the first zone that matches, and if none match, fallback to the default
  const localeShippingRates =
    shippingRates.find((rate) =>
      rate.countryIsoCodes.includes(shippingCountry),
    ) || shippingRates.find((rate) => rate.countryIsoCodes.length === 0);

  const findFreeShipping = localeShippingRates?.services.find(
    (service) => service.service.freeShippingThresholdCents,
  );

  const price = findFreeShipping?.service.freeShippingThresholds
    .find((country) => country.countryIso === shippingCountry)
    ?.priceList.find((z) => z.currencyIsoCode === currencyCode);

  const messages = [
    { message: '30-day returns', onClick: handleOpen },
    ...(findFreeShipping
      ? [
          {
            message: `Free ${shippingCountry} shipping on orders above ${price?.priceGrossFormatted}`,
            onClick: handleOpen,
          },
        ]
      : []),
    { message: 'Lifetime warranty', onClick: handleOpenWarranty },
  ];

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      className="bg-background-brand-primary relative shrink-0 text-white"
      height="navigationAnnouncement"
    >
      <Marquee autoFill pauseOnHover>
        {messages.map(({ message, onClick }, i) => {
          return (
            <Box key={i} onClick={onClick}>
              <Box className="cursor-pointer px-6 text-sm hover:underline">
                {message}
              </Box>
            </Box>
          );
        })}
      </Marquee>
    </Box>
  );
};
