import { paths } from '@/src/packages/routes';
import { getRoute } from '@/src/packages/utils/routes';
import { Box } from '@/src/packages/components/ui/Box';
import Link from 'next/link';
import { NavigationLinks } from '@/src/packages/components/ui/Navigation/NavigationLinks';
import { NavigationItem } from '@/src/packages/components/ui/Navigation/NavigationItem';
import { NavigationLink } from '@/src/packages/components/ui/Navigation/NavigationLink';
import { pathParams } from '@/src/packages/routes/pathParams.enum';

export const NavigationDesktopPrimaryLinks = () => {
  return (
    <Box className="hidden md:block">
      <NavigationLinks>
        <NavigationItem>
          <Link
            href={getRoute({
              route: paths.RANGES_SINGLE,
              params: { [pathParams.RANGE_SINGLE]: 'dog' },
            })}
          >
            <NavigationLink>Shop</NavigationLink>
          </Link>
        </NavigationItem>
        <NavigationItem>
          <Link href={getRoute({ route: paths.ABOUT_INDEX })}>
            <NavigationLink>About</NavigationLink>
          </Link>
        </NavigationItem>
        <NavigationItem>
          <Link href={getRoute({ route: paths.CONTACT_INDEX })}>
            <NavigationLink>Contact</NavigationLink>
          </Link>
        </NavigationItem>
      </NavigationLinks>
    </Box>
  );
};
