import { ReactNode } from 'react';
import cx from 'classnames';
import { useNavigationContext } from '../NavigationContext';
import { Box } from '@/src/packages/components/ui/Box';
import { useBasketDrawerStore } from '@/src/packages/hooks/use-basket-drawer';

export const NavigationContainerInner = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isOpen: isBasketOpen } = useBasketDrawerStore((state) => state);

  const { isInversed, isPinned, isMobileMenuOpen } = useNavigationContext();

  return (
    <Box
      className={cx('flex w-full h-full')}
      style={{
        transition: 'border var(--transition)',
      }}
    >
      {children}
    </Box>
  );
};
