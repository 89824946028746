import { forwardRef, Ref } from 'react';
import { tv, VariantProps } from 'tailwind-variants';
import { Box, IBoxProps } from '../Box';
import { cn } from '@/src/packages/utils/cn';

export const inputStyles = tv({
  base: 'w-full appearance-none placeholder:text-text-secondary rounded-none transition-all border border-border-primary hover:border-border-primary-active hover:bg-background-secondary bg-background-primary',
  variants: {
    size: { default: 'px-4 h-form-control-default' },
  },
});

type InputVariants = VariantProps<typeof inputStyles>;

export type IInput = {
  isRequired?: boolean;
  isDisabled?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  testId?: string;
} & IBoxProps<HTMLInputElement> &
  InputVariants;

export const Input = forwardRef((props: IInput, ref: Ref<HTMLInputElement>) => {
  const {
    placeholder,
    type,
    id,
    name,
    autoComplete = false,
    defaultValue,
    isDisabled,
    onKeyDown,
    onPaste,
    onKeyUp,
    onChange,
    value,
    tabIndex = 0,
    onFocus,
    onBlur,
    size = 'default',
    testId,
    autoFocus,
    isRequired,
    className,
    ...rest
  } = props;

  return (
    <Box
      as="input"
      tabIndex={tabIndex}
      id={id}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onPaste={onPaste}
      defaultValue={defaultValue}
      value={value}
      name={name}
      autoComplete={autoComplete === false ? 'off' : 'on'}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disabled={isDisabled}
      ref={ref}
      className={cn(inputStyles({ size }), className)}
      {...(isRequired && { required: true })}
      {...(testId && { 'data-testid': testId })}
      {...rest}
    />
  );
});

Input.displayName = 'Input';
