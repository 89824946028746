import { Box } from '@/src/packages/components/ui/Box';
import { NavigationLinks } from '@/src/packages/components/ui/Navigation/NavigationLinks';
import { NavigationItem } from '@/src/packages/components/ui/Navigation/NavigationItem';
import { NavigationLink } from '@/src/packages/components/ui/Navigation/NavigationLink';
import {
  useBasketDrawerStore,
  useLocaleDrawer,
} from '@/src/packages/hooks/use-basket-drawer';
import { NavigationLocaleSelectorTrigger } from '@/src/packages/components/ui/Navigation/NavigationLocaleSelector';
import { useBasket } from '@/src/packages/components/contexts/basket.context';
import { Loader } from '@/src/packages/components/ui/Loader';
import { cn } from '@/src/packages/utils/cn';
import { tv } from 'tailwind-variants';

const basketIconStyles = tv({
  base: 'flex h-6 w-6 items-center justify-center rounded-md border',
  variants: {
    active: {
      true: 'bg-background-brand-primary text-white border-background-brand-primary',
      false:
        'hover:bg-background-brand-primary hover:text-white hover:border-background-brand-primary',
    },
  },
});

export const NavigationSecondaryLinks = () => {
  const { basket, isLoading } = useBasket();

  const { handleOpen } = useBasketDrawerStore((state) => state);

  const { handleOpen: handleOpenLocaleSelector } = useLocaleDrawer();

  return (
    <Box className="ml-auto">
      <NavigationLinks>
        <NavigationItem className="hidden lg:flex">
          <NavigationLink onClick={handleOpenLocaleSelector}>
            <NavigationLocaleSelectorTrigger />
          </NavigationLink>
        </NavigationItem>

        <NavigationItem>
          {isLoading ? (
            <Loader />
          ) : (
            <Box
              as="button"
              aria-label="Open basket"
              onClick={handleOpen}
              className={cn(
                basketIconStyles({
                  active:
                    basket?.totalItems && basket.totalItems > 0 ? true : false,
                }),
              )}
            >
              <Box className="relative -mt-[2px] text-sm">
                {basket?.totalItems}
              </Box>
            </Box>
          )}
        </NavigationItem>
      </NavigationLinks>
    </Box>
  );
};
