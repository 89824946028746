import { CSSProperties, ForwardedRef, forwardRef } from 'react';
import { useNavigationContext } from '../NavigationContext';
import { Box } from '@/src/packages/components/ui/Box';

const NavigationHamburgerLine = ({ style }: { style: CSSProperties }) => {
  return (
    <div
      className="absolute left-0 w-full"
      style={{
        height: 1,
        backgroundColor: 'currentColor',
        transformOrigin: 'center',
        transition: 'opacity .2s ease,transform .2s ease',
        ...style,
      }}
    />
  );
};

export const NavigationHamburger = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const { isMobileMenuOpen, handleOpenMobileMenu, handleCloseMobileMenu } =
      useNavigationContext();

    return (
      <Box className="flex items-center md:hidden" height="full">
        <Box
          as="button"
          ref={ref}
          className="flex h-8 cursor-pointer flex-col items-center justify-center"
          onClick={() =>
            isMobileMenuOpen ? handleCloseMobileMenu() : handleOpenMobileMenu()
          }
          aria-label={isMobileMenuOpen ? 'Close navigation' : 'Open navigation'}
        >
          <Box
            className="relative"
            style={{
              width: '18px',
              height: '10px',
            }}
          >
            <NavigationHamburgerLine
              style={{
                top: '0px',
                ...(isMobileMenuOpen && {
                  transform: 'translateY(5px) rotate(45deg)',
                }),
              }}
            />
            <NavigationHamburgerLine
              style={{
                top: '5px',
                ...(isMobileMenuOpen && { opacity: 0 }),
              }}
            />
            <NavigationHamburgerLine
              style={{
                top: '10px',
                ...(isMobileMenuOpen && {
                  transform: 'translateY(-5px) rotate(-45deg)',
                }),
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  },
);

NavigationHamburger.displayName = 'NavigationHamburger';
