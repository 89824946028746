import { ReactNode, useState } from 'react';
import { createContext } from '@bitmap/utils/utils/createContext';
import { Stack } from '@/src/packages/components/ui/Stack';

export const [NavigationLinksContext, useNavigationLinksContext] =
  createContext<{
    hoveringItem: HTMLDivElement | null;
    setHoveringItem(item: HTMLDivElement): void;
  }>();

export const NavigationLinks = ({ children }: { children: ReactNode }) => {
  const [hoveringItem, setHoveringItem] = useState<HTMLDivElement | null>(null);

  return (
    <NavigationLinksContext value={{ hoveringItem, setHoveringItem }}>
      <Stack
        spaceX={5}
        height="full"
        childClassName="h-full"
        direction="horizontal"
      >
        {children}
      </Stack>
    </NavigationLinksContext>
  );
};
