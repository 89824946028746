import { cn } from '@/src/packages/utils/cn';
import { Box, IBoxProps } from '../../../Box';

export const NavigationMobileDrawerSecondaryLink = (props: IBoxProps) => {
  const { className, ...rest } = props;

  return (
    <Box
      className={cn('py-3 text-3xl leading-none transition-all', className)}
      {...rest}
    />
  );
};
