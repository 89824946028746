import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useWindowScroll, useWindowSize } from 'react-use';
import { useDisclosure } from '@bitmap/utils/hooks/use-disclosure';
import { useScrollDirection } from '@bitmap/utils/hooks/use-scroll-direction';
import { NavigationSubmenuIds } from './NavigationSubmenu';

export const useNavigation = () => {
  const {
    isOpen: isBasketOpen,
    onClose: handleCloseBasket,
    onOpen: handleOpenBasket,
  } = useDisclosure();

  const {
    isOpen: isMobileMenuOpen,
    onClose: handleCloseMobileMenu,
    onOpen: handleOpenMobileMenu,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  const {
    isOpen: isSubmenuOpen,
    onClose: onCloseSubMenu,
    onOpen: onOpenSubMenu,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  const [activeSubmenuId, setActiveSubmenuId] =
    useState<NavigationSubmenuIds | null>(null);

  const [isPinned, setPinned] = useState(false);

  const { y } = useWindowScroll();

  const { width } = useWindowSize();

  const pathname = usePathname();

  useEffect(() => {
    handleCloseBasket();
    handleCloseMobileMenu();
    handleCloseSubMenu();
  }, [pathname]);

  useEffect(() => {
    // setPinned(y > 0);
  }, [y]);

  useEffect(() => {
    handleCloseMobileMenu();
  }, [width]);

  const scrollDirection = useScrollDirection();

  const handleOpenSubMenu = (id: NavigationSubmenuIds) => {
    setActiveSubmenuId(id);
    onOpenSubMenu();
  };

  const handleCloseSubMenu = () => {
    setActiveSubmenuId(null);
    onCloseSubMenu();
  };

  return {
    y,
    activeSubmenuId,
    isPinned,
    setPinned,
    scrollDirection,
    isMobileMenuOpen,
    handleOpenMobileMenu,
    handleCloseMobileMenu,
    isBasketOpen,
    handleCloseBasket,
    handleOpenBasket,
    isSubmenuOpen,
    handleOpenSubMenu,
    handleCloseSubMenu,
  };
};
